<template>
  <div class="right">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的红包" name="first">
        
          <div style="margin-top: 27px">
            <div class="title">
              <div class="container_table">
                <el-table
                  :header-cell-style="headeRowClass"
                  :data="tableData"
                  stripe
                  style="width: 100%"
                  :default-sort="{ prop: 'date', order: 'descending' }"
                >
                  <el-table-column
                    prop="source"
                    label="
                    红包名称"
                    width="300"
                    align="center"
                    sortable
                  >
                  </el-table-column>
                  <el-table-column
                    prop="money"
                    label="金额"
                  
                    align="center"
                    sortable
                  >
                  </el-table-column>
                   <el-table-column
                    prop="get_time"
                    label="获取时间"
                    width="180"
                    align="center"
                    sortable
                  >
                  </el-table-column>
                  <el-table-column
                    prop="source"
                    label="来源"
                    align="center"
                    sortable
                  >
                  </el-table-column>
                </el-table>
                <el-pagination
                  class="fy"
                  small
                  @size-change="handleSizeChange"
                  :page-size="pagesize"
                  :page-sizes="[10, 20, 30]"
                  layout="total, sizes, prev, pager, next, jumper"
                  @current-change="current_change"
                  :total="total"
                  background
                >
                </el-pagination>
              </div>
            </div>
          </div>
        
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Balanced, BalancedList } from '../../../API/Fuwu'
import {selectRedEnvelopesByAid} from "../../../API/ZZD/coupon.js"
export default {
  components: {

  },
  data () {
    return {
      activeName: 'first', //默认选项卡初始化1
      yue: '', // 余额
      years: '',
      nian: '',// 年
      tab: 0,//0 列表   1提现页面
      total: 0,//默认数据总数
      pagesize: 10,//每页的数据条数
      currentPage: 1,//默认开始页面
      tableData: [], // 提现记录列表
    };
  },
  mounted () {

    this.BalancedList();
  },

  methods: {
    handleClick (tab, event) {
    },
    // 更改表头样式
    headeRowClass ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
      }
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.BalancedList()
    }, handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.BalancedList();
    },
  
   
    // 红包记录列表
    BalancedList () {
      let id = localStorage.getItem('ZZId')
      selectRedEnvelopesByAid(id, this.currentPage, this.pagesize).then(res => {
        this.tableData = res.data.data
        console.log(res)
        this.total = res.data.total
      })
    },

  },
};
</script>

<style  scoped>
.yue {
  width: 100%;
  height: 110px;
}
.yue-list {
  width: 100%;

  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.fy {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 27px;
}
</style>